// src/CustomerDetails.js
import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../utils/axios';
import './CustomerDetails.css'; // Import CSS for styling

const fetchDetails = async (id) => {
    const response = await axiosInstance.get(`api/${id}`); // Adjust the endpoint if needed
    return response.data.result;
};

const CustomerDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate(); // Hook to programmatically navigate
    const { data, isLoading, isError } = useQuery({
        queryKey: ['customer', id],
        queryFn: () => fetchDetails(id),
    });

    if (isLoading) return <p>Loading...</p>;
    if (isError) return <p>Error fetching customer details</p>;

    const mediaUrl = `https://maricho.tech/file/${data.mediaName}`; // Construct the media URL

    return (
        <div className="customer-details">
            {/* Top Image */}
            <img
                src="https://scontent.fhre2-2.fna.fbcdn.net/v/t39.30808-6/347821109_1611195909292063_8103654506535545583_n.jpg?_nc_cat=111&ccb=1-7&_nc_sid=6ee11a&_nc_ohc=yLh2HALs9mAQ7kNvgEZMrQg&_nc_zt=23&_nc_ht=scontent.fhre2-2.fna&_nc_gid=AD9wNKEibXkeym9YxkGjiIv&oh=00_AYD9pjkGqqR7cv6wpAO4T1TIWfSPQZbIV7a4xZZiqCP0HA&oe=67254866" // Replace with your actual image URL
                alt="Banner"
                className="top-image"
            />
            <h1>Customer Details</h1>
            <p><strong>Name:</strong> {data.fullName}</p>
            <p><strong>Cell Number:</strong> {data.cellNumber}</p>
            <p><strong>Story:</strong> {data.story || 'No story available.'}</p>
            {data.mediaName && (
                 <div className="media-container">
                 <strong>Media:</strong>
                 {data.mediaName.endsWith('.mp4') || 
                  data.mediaName.endsWith('.webm') || 
                  data.mediaName.endsWith('.ogg') || 
                  data.mediaName.endsWith('.mov') ? (  // Added support for .MOV
                     <video 
                         controls 
                         className="media"
                     >
                         <source src={mediaUrl} type="video/mp4" />
                         <source src={mediaUrl} type="video/quicktime" /> {/* For .MOV files */}
                         Your browser does not support the video tag.
                     </video>
                 ) : (
                     <img 
                         src={mediaUrl} 
                         alt={data.fullName} 
                         className="media"
                     />
                 )}
             </div>
                 
            )}
            <p><strong>Created:</strong> {new Date(data.created).toLocaleString()}</p>
            <p><strong>Updated:</strong> {new Date(data.updated).toLocaleString()}</p>
            <button className="return-button" onClick={() => navigate(-1)}>Return</button>
        </div>
    );
}

export default CustomerDetails; // Exporting the CustomerDetails component