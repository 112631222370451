import './App.css';
import Complete from './pages/Complete';
import Home from './pages/Home';
import ErrorPage from './pages/ErrorPage';
import { Route,Routes } from 'react-router-dom';
import Dashboard from './pages/admin/Dashboard';
import { MantineProvider } from '@mantine/core';
import CustomerDetails from './pages/admin/CustomerDetails';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
 
const queryClient = new QueryClient()
function App() {
  return (
    <MantineProvider >
    <QueryClientProvider client={queryClient}>

   <Routes>
        <Route path="/error-page" element={<ErrorPage />} />
        <Route path="/dashboard" element={<Dashboard />}/>
        <Route path="/customer/:id" element={<CustomerDetails />}/> 
        <Route path="/complete" element={<Complete />} />
        <Route path="/" element={<Home />}/>

    </Routes>
    </QueryClientProvider>
    </MantineProvider>


   
 
  );
}

export default App;
